import React, { useCallback, useEffect, useReducer, useState } from 'react'
import { makeStyles } from '@mui/styles'
import CustomDialog from 'app/sharedComponents/CustomDialog'
import { useDispatch, useSelector } from 'react-redux'
import {
	addAppointmentModal,
	SetCountryCodesList,
	setEditApptData,
	appointmentRefresh,
	apptForSelectedClient,
	setApptOpenedFrom,
	setApptPrepopulationData,
	setAppointmentDate,
} from 'services/Appointments/action'
import CareAppointment from './CareAppointment'
import NonCareAppointment from './NonCareAppointment'
import CustomBtn from 'app/sharedComponents/Button'
import CancelBtn from 'app/sharedComponents/CancelButton'
import PaymentHandler from './PaymentHandler'
import { useFormik } from 'formik'
import * as yup from 'yup'
import _ from 'lodash'
import moment from 'moment'
import DoctorAPI from 'services/CalendarApi/DoctorList/api.js'
import PriceAPI from 'services/Settings/Catalog/Price/index'
import countryLookupAPI from 'services/register/api'
import UserManagementAPI from 'services/Settings/UserManagement/api'
import PatientListAPI from 'services/patient/patientDetails/api'
import QuickSaleAPI from 'services/QuickSale/api.js'
import AppointmentAPI from 'services/CalendarApi/AddAppoinment/api.js'
import SpecialityAPI from 'services/CalendarApi/Speciality/api.js'
import { careInitialData, nonCareInitialData } from './helperMethods'
import { initialState, reducerMethod, clientInitialState, clientReducerMethod } from './reducer'
import { decryption, encryption, failureLogin, getResponseMessage } from 'utils'
import { hideLoader, showLoader } from 'services/loader/action'
import { showMessage } from 'app/store/fuse/messageSlice'
import history from '@history'
import secureLocalStorage from 'react-secure-storage'
import { customer_Uuid, setCurrentSelectedClient } from 'services/patient/roleBasedUuid/action'
import { updateCurrentIndex } from 'services/sidemenu/action'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
	footerAction: {
		background: '#D9EAFF50',
		paddingInline: 22,
		paddingBlock: 8,
		display: 'flex',
		justifyContent: 'space-between',
		gap: 22,
	},
	container: {},
}))

const customStyles = {
	dialogContent: {
		padding: 0,
	},
}
// Appointmentmodes for isPracticeLogin. But for Doctor/physiotherapist we get through API based on doctor/physiotherapist
const appointmentModes = [
	{
		label: 'at-clinic',
		LabelTxt: 'In-person',
		value: '0',
	},
	{
		label: 'on-line',
		LabelTxt: 'Online',
		value: '1',
	},
	{
		label: 'at-home',
		LabelTxt: 'Home service',
		value: '2',
	},
]

const AddAppointment = () => {
	const LocationPath = window?.location?.search?.split('&')?.[1]
	const classes = useStyles()
	const dispatch = useDispatch()
	const [apptState, apptDispatch] = useReducer(reducerMethod, initialState)
	const [clientState, clientDispatch] = useReducer(clientReducerMethod, clientInitialState)
	const [price, setPrice] = useState('')
	const [priceValue, setPriceValue] = useState('')
	const { openAddAppointmentModal, countryCodeList, editAppointmentData, preSelectedClient, openedFrom, prepopulationData } = useSelector(
		(state) => state?.createAppointment
	)

	const dynamicMenu = useSelector((state) => state?.dynamicMenu)
	const { mastTentTypeName, userRole, defaultIntegrationForOnlineMeeting } = dynamicMenu?.userData
	const isCareFlow = _.isEqual(mastTentTypeName, 'Hospital') || _.isEqual(mastTentTypeName, 'Clinic') || _.isEqual(mastTentTypeName, 'Ayurveda')
	const { specialistId } = useSelector((state) => state?.calendar)
	const { mastuuid, uuid } = useSelector((state) => state.Authentication)
	const currentCountCode = dynamicMenu?.userData?.countryCode
	const { countryName } = dynamicMenu?.userData
	const [selectTentUserId, setSelectTentUserId] = useState('')
	const currentSelectedClient = useSelector((state) => state?.RoleIdReducer?.currentSelectedClient)
	const errorMessages = useSelector((state) => state?.errorMessages)
	const isSpecialistLogin = _.isEqual(userRole, 'Doctor') || _.isEqual(userRole, 'Physiotherapist') || _.isEqual(userRole, 'Ayurvedic')
	const isEditAppt = !_.isEmpty(editAppointmentData)
	const [startPaymentProcess, setStartPaymentProcess] = useState(false)
	const [countryCode, setCountryCode] = useState(null)
	const [countryCodeLength, setCountryCodeLength] = useState(0)
	const [nextDate, setNextDate] = useState(null)
	const [countryCodeMinLength, setCountryCodeMinLength] = useState(1)
	const [firstFilterDate, setFirstFilterDate] = useState({})
	const [billingData, setBillingData] = useState(null)
	const [searchText, setSearchText] = useState('')
	const [searchParams, setSearchParams] = useState({
		searchKey: '',
	})
	const [timeSlotLoading, setTimeSlotLoading] = useState(false)
	const [timeSlotLoadingNonCare, setTimeSlotLoadingNonCare] = useState(false)
	const HomeServiceType = useSelector((state) => state?.dynamicMenu?.userData?.mastTentTypeName)

	// #################################################################################################################

	// Patients or Clients Search Field
	const performClientSearch = (text) => {
		clientDispatch({
			type: 'setIsClientLoading',
			data: true,
		})
		const onSuccess = (res) => {
			const decryptedRes = decryption(res)
			clientDispatch({
				type: 'setClientDataList',
				data: decryptedRes?.data,
			})
			clientDispatch({
				type: 'setIsClientLoading',
				data: false,
			})
		}
		const onFailure = (err) => {
			clientDispatch({
				type: 'setClientDataList',
				data: [],
			})
			clientDispatch({
				type: 'setIsClientLoading',
				data: false,
			})
		}
		QuickSaleAPI.globalSearch(mastuuid, { name: text }).then(onSuccess, onFailure)
	}
	// #################################################################################################################

	// // Patients or Clients Search Field
	// const SearchClients = () => {
	// 	clientDispatch({
	// 		type: 'setClientsLoading',
	// 		data: true,
	// 	})
	// 	const onSuccess = (res) => {
	// 		const decryptedRes = decryption(res)
	// 		clientDispatch({
	// 			type: 'setClientsList',
	// 			data: decryptedRes?.data,
	// 		})
	// 		clientDispatch({
	// 			type: 'setClientsLoading',
	// 			data: false,
	// 		})
	// 	}
	// 	const onFailure = (err) => {
	// 		clientDispatch({
	// 			type: 'setClientsList',
	// 			data: [],
	// 		})
	// 		clientDispatch({
	// 			type: 'setClientsLoading',
	// 			data: false,
	// 		})
	// 	}
	// 	!_.isEmpty(searchParams?.searchKey) && QuickSaleAPI.globalSearch(mastuuid, { name: searchParams?.searchKey }).then(onSuccess, onFailure)
	// }

	useEffect(() => {
		if (searchParams?.searchKey.trim() !== '') {
			// SearchClients()
		} else {
			clientDispatch({
				type: 'setClientsList',
				data: [],
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams])

	const clientSearcher = _.debounce(
		(value) => {
			setSearchParams({
				searchKey: value,
			})
		},
		[100]
	)

	const handleNavigation = (res, body) => {
		dispatch(customer_Uuid(`${body?.custId}`))
		secureLocalStorage.setItem('custUuid', `${body?.custId}`)
		if (clientState?.clientStatus === 'Customer') {
			history.push({
				pathname: `/clientinfo/${body?.custId}?tabId=appointment`,
			})
			dispatch(updateCurrentIndex('clients'))
		} else if (clientState?.clientStatus === 'Lead') {
			history.push({
				pathname: `/leads`,
			})
			dispatch(updateCurrentIndex('leads'))
		}
	}

	const saveAppointment = (e) => {
		dispatch(showLoader(`${isEditAppt ? 'Rescheduling' : 'Creating'} appointment please wait...`))
		let mapped =
			!_.isEmpty(apptState?.chipDays) && apptState?.serial
				? _.map(apptState?.chipDays, (item, index) => {
						return _.lowerCase(item?.label)
				  })
				: apptDispatch({ type: 'setSerialErr', data: 'Please choose any one of the day' })

		let body = isCareFlow
			? //CARE FLOW BODY
			  {
					custId: e?.clientName?.custUuid,
					tentId: mastuuid,
					tentUserId: e?.specialistName?.tentUserUuid,
					apptCatId: '',
					scheduledOn: moment(e?.date).format('YYYY-MM-DD'),
					...(apptState?.serial && { scheduledEndAt: moment(apptState?.pickerValue.at(1)).format('YYYY-MM-DD') }),
					...(apptState?.serial && { scheduledInterval: 'daily' }),
					...(apptState?.serial && { scheduledIntervalDay: _.uniqBy(mapped) }),
					scheduledTime: moment(e?.timeSlot?.startTime, 'hh:mm A').format(`HH:mm:ss${'+05:30'}`),
					scheduledPeriod: e?.timeSlot?.intervalInMins,
					scheduledPeriodType: 'Mins',
					...(!_.isEmpty(e?.speciality) && { specialityId: e?.speciality?.specialityUuid || [''] }),
					onOff: e?.appointmentMode?.value,
					custMail: true,
					custSms: true,
					tentSms: true,
					tentMail: true,
					meetingLink: googleIntegrateLink || null,
					plannedProcedure: !_.isEmpty(e?.procedureCategory) ? [e?.procedureCategory.treatmentCatalogUuid] : [],
					totalAppointmentPrice: e?.paymentMandatory
						? e?.feesCalcMethod === 'specialistConsultationFees'
							? e?.timeSlot?.consultantionFees
							: e?.procedureCategory.totalPrice
						: e?.timeSlot?.consultantionFees,
					notes: e?.notes,
					type: 'TENT',
					appointmentSource: 'b2bflash',
					isPaymentMandatory: e?.paymentMandatory,
			  }
			: {
					custId: e?.clientName?.custUuid,
					tentId: mastuuid,
					tentUserId: e?.specialistName?.tentUserUuid || '',
					apptCatId: '',
					scheduledOn: moment(e?.date).format('YYYY-MM-DD'),
					...(apptState?.serial && { scheduledEndAt: moment(apptState?.pickerValue.at(1)).format('YYYY-MM-DD') }),
					...(apptState?.serial && { scheduledInterval: 'daily' }),
					...(apptState?.serial && { scheduledIntervalDay: _.uniqBy(mapped) }),
					scheduledTime: moment(e.timeSlot?.formattedTime, 'hh:mm A').format(`HH:mm:ss${'+05:30'}`),
					scheduledPeriod: e?.timeSlot?.consultantionDuration,
					scheduledPeriodType: 'Mins',
					onOff: e?.appointmentMode?.value,
					custMail: true,
					meetingLink: googleIntegrateLink || null,
					custSms: true,
					tentSms: true,
					tentMail: true,
					venue_space: e?.spaceTaken,
					plannedProcedure: [],
					specialityId: [e?.facility?.specialityUuid],
					totalAppointmentPrice: priceValue,
					notes: e?.notes,
					type: 'TENT',
					appointmentSource: 'b2bflash',
					isPaymentMandatory: e?.paymentMandatory,
			  }

		let updateBody = {
			uuid: editAppointmentData?.uuid,
			tentUserId: e?.specialistName?.tentUserUuid || '',
			scheduledOn: moment(e?.date).format('YYYY-MM-DD'),
			scheduledTime: isCareFlow
				? moment(e?.timeSlot?.startTime, 'hh:mm A').format(`HH:mm:ss${'+05:30'}`)
				: moment(e.timeSlot?.formattedTime, 'hh:mm A').format(`HH:mm:ss${'+05:30'}`),
			custMail: true,
			custSms: true,
			tentSms: true,
			tentMail: true,
			notes: e?.notes,
			...(isCareFlow && !_.isEmpty(e?.speciality) && { specialityId: e?.speciality?.specialityUuid || [''] }),
			...(isCareFlow && { plannedProcedure: !_.isEmpty(e?.procedureCategory) ? [e?.procedureCategory.uuid] : [] }),
			...(isCareFlow && { apptCatId: '' }),
			...(!isCareFlow && { specialityId: [e?.facility?.specialityUuid] }),
		}

		let onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === 'success') {
				setGoogleIntegrateLink('')
				// CARE
				if (isCareFlow && !isEditAppt) {
					// assignMembership(e)
					if (e?.paymentMandatory) {
						if (apptState?.serial === false) {
							setBillingData({ apptRes: res, lastBody: body, rawBody: e })
							setStartPaymentProcess(true)
						} // disabling session payment
						else if (apptState?.serial === true) {
							dispatch(appointmentRefresh({ apptRes: res, lastBody: body }))
							onClose()
						}
					} else {
						dispatch(appointmentRefresh({ apptRes: res, lastBody: body }))
						handleNavigation(res, body) // res - response from appointmwent success API, where body is POST body of this appointment
						onClose()
					}
				}

				// Non-Care
				if (!isCareFlow && !isEditAppt) {
					// assignMembership(e)
					if (e?.paymentMandatory) {
						// disabling session payment
						if (apptState?.serial === false) {
							setBillingData({ apptRes: res, lastBody: body, rawBody: e })
							setStartPaymentProcess(true)
						} else if (apptState?.serial === true) {
							dispatch(appointmentRefresh({ apptRes: res, lastBody: body }))
							onClose()
						}
					} else {
						dispatch(appointmentRefresh({ apptRes: res, lastBody: body }))
						handleNavigation(res, body) // res - response from appointmwent success API, where body is POST body of this appointment
						onClose()
					}
				}

				if (isEditAppt) {
					onClose()
					dispatch(appointmentRefresh({ apptRes: res, lastBody: body, isRescheduled: true }))
				}

				dispatch(
					showMessage({
						message: getResponseMessage(res.data, errorMessages, `Appointment ${isEditAppt ? 'Updated' : 'Created'}`),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				// clearing the current selected client
				dispatch(setCurrentSelectedClient(null))
			}
		}

		let onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}

		// Add Apoitment API
		if (!isEditAppt) {
			apptState?.serial
				? AppointmentAPI.addSerialAppointment(body).then(onSuccess, onFailure)
				: AppointmentAPI.addAppointment(body).then(onSuccess, onFailure)
		}
		// Edit appointment API
		else if (isEditAppt) {
			AppointmentAPI.appointmentUpdate(updateBody).then(onSuccess, onFailure)
		}
	}
	const careInitialData = {
		clientName: null,
		clientId: '',
		custMobile: '',
		custEmail: '',
		tentUserId: '',
		appointmentMode: null,
		specialistName: null,
		feesCalcMethod: 'specialistConsultationFees',
		custSms: false,
		custMail: false,
		tentSms: false,

		tentMail: false,
		category: null,
		date: _.isEqual(openedFrom, 'openbuttonCalendar')
			? moment(new Date()).format('YYYY-MM-DD')
			: moment(prepopulationData?.date).format('YYYY-MM-DD'),
		timeSlot: null,
		duration: null,
		procedureCategory: null,
		speciality: [],
		addMore: false,
		clientEmail: '',
		notes: '',
		paymentMandatory: false,
		membershipName: null,
		membershipValidity: null,
		membershipSession: '',
		membershipStartDate: moment(new Date()).format('YYYY-MM-DD 00:00:00') || null,
		membershipEndDate: null,
	}
	const nonCareInitialData = {
		clientName: null,
		clientId: '',
		custMobile: '',
		custEmail: '',
		tentUserId: '',
		appointmentMode: null,
		specialistName: null,
		facility: null,
		custSms: false,
		custMail: false,
		tentSms: false,
		tentMail: false,
		category: null,
		date: _.isEqual(openedFrom, 'openbuttonCalendar')
			? moment(new Date()).format('YYYY-MM-DD')
			: moment(prepopulationData?.date).format('YYYY-MM-DD'),
		timeSlot: null,
		duration: '',
		price: '',
		space: 0,
		spaceTaken: 1,
		addMore: false,
		clientEmail: '',
		notes: '',
		feesCalcMethod: 'facilityPrice',
		paymentMandatory: false,
	}
	const careValidationSchema = yup.object({
		// clientName: yup.object().nullable().required('please enter the patient name'),
		custMobile: yup
			.string()
			.required('Please enter a valid mobile number')
			.nullable()
			.matches(/^[0-9\s]+$/, 'Only numbers are allowed')
			.min(countryCodeMinLength, `Minimum ${countryCodeMinLength} digits required`)
			.max(countryCodeLength, `Maximum ${countryCodeLength} digits only allowed`),
		specialistName: yup.object().nullable().required('Please select a specialist'),
		procedureCategory: yup.object().when(['paymentMandatory'], {
			is: (paymentMandatory) => {
				let doValidate = paymentMandatory
				// when doValidate returns TRUE, then gets validated else otherwise will not validate
				return doValidate
			},
			then: yup.object().nullable().required('Procedure must be selected, when payment is mandatory'),
			otherwise: yup.object().nullable().required('Procedure must be selected'),
		}),
		membershipValidity: yup.object().when(['membershipName'], {
			is: (membershipName) => {
				let doValidate = !_.isEmpty(membershipName)
				// when doValidate returns TRUE, then gets validated else otherwise will not validate
				return doValidate
			},
			then: yup.object().nullable().required('Please select a validity period'),
			otherwise: yup.object().nullable(),
		}),
		appointmentMode: yup.object().nullable().required('Please select a appointment mode'),
		timeSlot: yup.object().nullable().required('Please select a time'),
		clientEmail: yup.string().email('You must enter a valid email').nullable(),
		notes: yup
			.string()
			.matches(/^[^<>;#$]+$/, 'Cannot contain (<>#$) special characters in the notes')
			.nullable(),
	})

	const nonCareValidationSchema = yup.object({
		custMobile: yup
			.string()
			.required('Please enter a valid mobile number')
			.nullable()
			.matches(/^[0-9\s]+$/, 'Only numbers are allowed')
			.min(countryCodeMinLength, `Minimum ${countryCodeMinLength} digits required`)
			.max(countryCodeLength, `Maximum ${countryCodeLength} digits only allowed`),
		appointmentMode: yup.object().nullable().required('Please select an appointment mode'),
		facility: yup.object().nullable().required('Please select a facility'),
		timeSlot: yup.object().nullable().required('Please select a time'),
		clientEmail: yup.string().email('You must enter a valid email').nullable(),
		notes: yup
			.string()
			.matches(/^[^<>;#$]+$/, 'Cannot contain (<>#$) special characters in the notes')
			.nullable(),
		space: yup.number().required('Space is required').nullable(),
		spaceTaken: yup
			.number()
			.required('Space taken is required')
			.nullable()
			.test('spaceTakenTest', 'Space taken cannot be greater than available space', function (value) {
				const { spaceTaken } = this.parent
				return spaceTaken == null || spaceTaken <= formik?.values?.timeSlot?.balanceSpace
			}),
	})

	const handleAppointmentSubmit = (e) => {
		if (clientState?.isNewClient) {
			dispatch(showLoader(` Adding new ${isCareFlow ? 'patient' : 'client'}...`))
			let newClientBody = {
				leadStatus: 'Customer',
				tentUserUuid: uuid,
				custName: e?.clientName,
				custCustomId: e?.clientId,
				custEmail: e?.clientEmail || null,
				custCountryCode: countryCode?.mastLookupKey,
				custMobileNo: e?.custMobile,
				custMobileNotification: false,
				custEmailNotification: false,
				isAddress: false,
			}
			let decryptKey = encryption(newClientBody)
			const onSuccess = (res) => {
				let successData = decryption(res)
				dispatch(hideLoader())
				if (successData.status === 'success') {
					dispatch(
						showMessage({
							message: getResponseMessage(successData, errorMessages),
							autoHideDuration: 3000,
							anchorOrigin: {
								vertical: 'top',
								horizontal: 'right',
							},
							variant: 'success',
						})
					)
					let newClient = {
						upcomingApp: false,
						status: null,
						custUuid: successData.data?.custUuid,
						custName: e?.clientName,
						custCustomId: e?.clientId,
						custEmail: e?.clientEmail || null,
						custMobileNo: e?.custMobile,
						appointmentCount: 0,
						custLogoUuid: null,
						custThumbNailPath: null,
						originalFilePath: null,
						custEmailNotification: false,
						billStatus: null,
						statusPharma: null,
						custMobileNotification: false,
						custCountryCode: countryCode?.mastLookupKey,
					}

					formik?.setFieldValue('clientName', newClient)
					saveAppointment({ ...e, clientName: newClient })
					clientDispatch({
						type: 'setIsNewClient',
						data: false,
					})
				}
			}

			const onFailure = (err) => {
				let decryptedErr = failureLogin(err)
				dispatch(hideLoader())
				dispatch(
					showMessage({
						message: getResponseMessage(decryptedErr, errorMessages),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'error',
					})
				)
			}
			QuickSaleAPI.postPatient(mastuuid, decryptKey?.plainText, decryptKey?.publicKey).then(onSuccess, onFailure)
		} else {
			saveAppointment(e)
		}
	}

	const formik = useFormik({
		initialValues: isCareFlow ? careInitialData : nonCareInitialData,
		validationSchema: isCareFlow ? careValidationSchema : nonCareValidationSchema,
		enableReinitialize: true,
		onSubmit: handleAppointmentSubmit,
	})

	const onClose = (event, reason) => {
		if (reason === 'backdropClick') {
			return null
		} else {
			dispatch(addAppointmentModal(false))
			dispatch(setEditApptData({}))
			dispatch(apptForSelectedClient(null))

			apptDispatch({
				type: 'reset',
			})
			clientDispatch({
				type: 'reset',
			})
			formik?.resetForm()
			setSearchText('')
			setStartPaymentProcess(false)
			setBillingData(null)
			setCountryCode(null)
			dispatch(setApptOpenedFrom(null))
			dispatch(setApptPrepopulationData(null))
			setSelectTentUserId('')
			setGoogleIntegrateLink('')
			// formik?.setFieldValue('date', null)
			// if (_.isEqual(LocationPath, 'leaf=cal') || !isCareFlow) {
			// 	return ''
			// } else {
			// 	return formik?.setFieldValue('date', null)
			// }
		}
	}

	// THIS USEEFFECT PREPOPULATES THE CLIENT INFO IF THE ADD APPOINTMENT IS OPENED FROM THE CLIENT OR PATIENT MODULE:
	useEffect(() => {
		if (!_.isEmpty(preSelectedClient) && openAddAppointmentModal && !_.isEmpty(countryCodeList)) {
			// Pre- populating Client Details
			formik?.setFieldValue('clientName', preSelectedClient)
			formik?.setFieldValue('clientId', preSelectedClient?.custCustomId)
			formik?.setFieldValue('custMobile', preSelectedClient?.custMobileNo)
			formik?.setFieldValue('clientEmail', preSelectedClient?.custEmail || '')

			// setSearchText(preSelectedClient?.custName)
			clientDispatch({
				type: 'setClientSearchText',
				data: preSelectedClient?.custName,
			})
			clientDispatch({
				type: 'setclientStatus',
				data: preSelectedClient?.leadStatus,
			})
			clientDispatch({
				type: 'setIsNewClient',
				data: false,
			})
			handleCloseDropdown()
			// Country Code
			let code = _.find(countryCodeList, { mastLookupKey: preSelectedClient?.custCountryCode })
			setCountryCode(code)
			setCountryCodeLength(parseInt(code?.mastLookupValue))
			setCountryCodeMinLength(parseInt(code?.minimumLength))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [preSelectedClient, openAddAppointmentModal, countryCodeList])

	useEffect(() => {
		// APPOINTMENT MODES:
		if (openAddAppointmentModal && !isEditAppt) {
			let tentapptparams = {
				tentId: mastuuid,
				tentUserId: selectTentUserId || '',
				scheduledOn: moment(formik?.values?.date).format('yyyy-MM-DD'),
			}

			DoctorAPI.getApptModesBasedOnTent(tentapptparams).then(
				(res) => {
					if (res?.data?.status === 'success') {
						setNextDate(res?.data?.nextDate)
						dispatch(
							setApptPrepopulationData({
								date: res?.data?.nextDate,
							})
						)

						formik?.setFieldValue('date', res?.data?.nextDate)
						let resModes = res?.data?.data
						let availbleModes =
							[
								...(_.includes(resModes, 'In-person') ? [appointmentModes.at(0)] : []),
								...(_.includes(resModes, 'Online') ? [appointmentModes.at(1)] : []),
								...(_.includes(resModes, 'Home') ? [appointmentModes.at(2)] : []),
							] || []

						apptDispatch({
							type: 'setAppointmentModes',
							data: availbleModes,
						})

						if (_.isEmpty(availbleModes)) {
							// clearing:
							formik?.setFieldValue('appointmentMode', null)
							formik?.setFieldValue('specialistName', null)
						}
					}
				},
				(err) => {
					apptDispatch({
						type: 'setAppointmentModes',
						data: [],
					})
				}
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, openAddAppointmentModal, formik?.values?.date])

	useEffect(() => {
		// APPOINTMENT MODES:
		if (openAddAppointmentModal && !isEditAppt) {
			let tentapptparams = {
				tentId: mastuuid,
				tentUserId: selectTentUserId || '',
				scheduledOn: moment(formik?.values?.date).format('yyyy-MM-DD'),
			}
			dispatch(showLoader('Loading please wait...'))
			DoctorAPI.getApptModesBasedOnTent(tentapptparams).then(
				(res) => {
					if (res?.data?.status === 'success') {
						dispatch(hideLoader())
						setNextDate(res?.data?.nextDate)
						dispatch(
							setApptPrepopulationData({
								date: res?.data?.nextDate,
							})
						)
						formik?.setFieldValue('date', res?.data?.nextDate)
						// dispatch(setAppointmentDate(res?.data?.nextDate))
						let resModes = res?.data?.data
						let availbleModes =
							[
								...(_.includes(resModes, 'In-person') ? [appointmentModes.at(0)] : []),
								...(_.includes(resModes, 'Online') ? [appointmentModes.at(1)] : []),
								...(_.includes(resModes, 'Home') ? [appointmentModes.at(2)] : []),
							] || []

						apptDispatch({
							type: 'setAppointmentModes',
							data: availbleModes,
						})
					}
				},
				(err) => {
					dispatch(hideLoader())
					apptDispatch({
						type: 'setAppointmentModes',
						data: [],
					})
				}
			)
		}
		// PROCEDURE LIST
		if (openAddAppointmentModal && isCareFlow) {
			PriceAPI.GetGroup(mastuuid).then(
				(res) => {
					if (res?.data?.status === 'success') {
						apptDispatch({
							type: 'setProcedureList',
							data: res?.data?.data?.getTreatmentCatalogList || [],
						})
					}
				},
				(err) => {
					apptDispatch({
						type: 'setProcedureList',
						data: [],
					})
				}
			)
		}

		// COUNTRY CODES
		if (openAddAppointmentModal && _.isEmpty(countryCodeList)) {
			countryLookupAPI.getCountryCode().then(
				(res) => {
					if (res?.data?.status === true) {
						let data = _.map(res?.data?.data, (item) => ({ ...item, mastLookupKey: `+${item?.mastLookupKey}` }))
						let code = _.orderBy(data, 'mastLookupKey', 'desc')
						dispatch(SetCountryCodesList(code))
					}
				},
				(err) => {
					dispatch(SetCountryCodesList([]))
				}
			)
		}

		// SPECIALITY for care
		if (openAddAppointmentModal && isCareFlow) {
			UserManagementAPI.getPracticeSpecaility(mastuuid).then(
				(res) => {
					apptDispatch({
						type: 'setSpecialityList',
						data: res.data.data || [],
					})
				},
				(err) => {
					apptDispatch({
						type: 'setSpecialityList',
						data: [],
					})
				}
			)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, openAddAppointmentModal, selectTentUserId])

	// Specialist List for care and non-care
	useEffect(() => {
		if (openAddAppointmentModal && !_.isEmpty(formik?.values?.appointmentMode)) {
			let doctorListParams = {
				tentId: mastuuid,
				appointmentMode: formik?.values?.appointmentMode?.label,
				// Incase if we need speciality as additional filter then it can be used below
				// ...(!_.isEmpty(formik?.values?.speciality) && { specialityId: (formik?.values?.speciality || []).map((o) => o.specialityUuid).toString() }),
			}

			DoctorAPI.getDoctorListBasedOnApptmodeAndSpeciality(doctorListParams).then(
				(res) => {
					if (res?.data?.status === 'success') {
						apptDispatch({
							type: 'setSpecialistList',
							data: res.data.data,
						})
					}
				},
				(err) => {
					apptDispatch({
						type: 'setSpecialistList',
						data: [],
					})
				}
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mastuuid, formik?.values?.appointmentMode, openAddAppointmentModal])

	useEffect(() => {
		// GET TIME SLOT LIST for CARE
		if (isCareFlow && openAddAppointmentModal && formik?.values?.specialistName && formik?.values?.appointmentMode) {
			setTimeSlotLoading(true)
			let careTimeSlotparams = {
				tentId: mastuuid,
				scheduledOn: moment(formik?.values?.date).format('yyyy-MM-DD'),
				tentUserId: formik?.values?.specialistName?.tentUserUuid,
				appointmentMode: formik?.values?.appointmentMode?.label,
				specialityId: formik?.values?.facility?.specialityUuid,
			}

			// Clearing the previous selected time slot of previous specialist:
			!isEditAppt && formik?.setFieldValue('timeSlot', null)

			apptDispatch({
				type: 'setTimeSlotList',
				data: [],
			})

			PatientListAPI.getTimeDuration(careTimeSlotparams).then(
				(res) => {
					let startTimeOnly = _.map(res?.data?.data || [], (item) => item || '')
					apptDispatch({
						type: 'setTimeSlotList',
						data: startTimeOnly || [],
					})
					setTimeSlotLoading(false)
				},
				(err) => {
					apptDispatch({
						type: 'setTimeSlotList',
						data: [],
					})
					setTimeSlotLoading(false)
				}
			)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		formik?.values?.date,
		formik?.values?.specialistName,
		formik?.values?.appointmentMode,
		formik?.values?.facility,
		openAddAppointmentModal,
		mastuuid,
	])

	useEffect(() => {
		// GET FACILITY LIST FOR NON-CARE
		if (!isCareFlow && openAddAppointmentModal && formik?.values?.date) {
			let facilityParams = { tentId: mastuuid, scheduledOn: moment(formik?.values?.date).format('yyyy-MM-DD') }
			SpecialityAPI.getNonCareSpeciality(facilityParams).then(
				(res) =>
					apptDispatch({
						type: 'setFacilityList',
						data: res?.data?.data,
					}),
				(err) =>
					apptDispatch({
						type: 'setFacilityList',
						data: [],
					})
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik?.values?.date, openAddAppointmentModal, mastuuid])

	useEffect(() => {
		// GET TIME SLOT LIST for NON-CARE
		setTimeSlotLoadingNonCare(true)
		if (!isCareFlow && openAddAppointmentModal && formik?.values?.facility) {
			!isEditAppt && formik?.setFieldValue('timeSlot', null)

			let nonCareTimeSlotparams = {
				tentId: mastuuid,
				scheduledOn: moment(formik?.values?.date).format('YYYY-MM-DD'),
				tentUserId: formik?.values?.specialistName ? formik?.values?.specialistName?.tentUserUuid : '',
				specialityId: formik?.values?.facility?.specialityUuid,
			}

			SpecialityAPI.getTimeSlot(nonCareTimeSlotparams).then(
				(res) => {
					if (res?.data?.status === 'success') {
						let filterDate = _.filter(res?.data?.data, (o) => o?.balanceSpace >= 1)
						let formattedDate = _.map(filterDate, (item) => ({ formattedTime: moment(item?.time, 'HH:mm').format('hh:mm A'), ...item }))
						setFirstFilterDate(formattedDate?.[0])
						apptDispatch({
							type: 'setTimeSlotList',
							data: formattedDate || [],
						})
					}
					setTimeSlotLoadingNonCare(false)
				},
				(err) => {
					apptDispatch({
						type: 'setTimeSlotList',
						data: [],
					})
					setTimeSlotLoadingNonCare(false)
				}
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openAddAppointmentModal, mastuuid, formik?.values?.facility, formik?.values?.date, formik?.values?.specialistName])

	// clearing the  the country code once the country is chaged:
	useEffect(() => setCountryCode(null), [currentCountCode])
	// Pre-population
	useEffect(() => {
		// Below condition doesnot allow to prepopulate in edit mode
		if (!isEditAppt) {
			// mode of appointment
			if (!_.isEmpty(apptState?.appointmentModes) && _.isEmpty(formik?.values?.appointmentMode)) {
				if (HomeServiceType === 'Home Service') {
					formik?.setFieldValue('appointmentMode', apptState?.appointmentModes.at(1))
				} else {
					formik?.setFieldValue('appointmentMode', apptState?.appointmentModes.at(0))
				}
			}

			// specialist
			if (isCareFlow && !_.isEmpty(apptState?.specialistList) && _.isEmpty(formik?.values?.specialistName)) {
				if (isSpecialistLogin) {
					let foundIndividualUser = _.find(apptState?.specialistList, { tentUserUuid: uuid })
					formik?.setFieldValue('specialistName', foundIndividualUser)
					setSelectTentUserId(foundIndividualUser?.tentUserUuid)
				} else {
					if (!_.isEmpty(specialistId?.tentUserUuid)) {
						// Selected doctor outside calender in Subheader of calender module
						let foundIndividualUser = _.find(apptState?.specialistList, { tentUserUuid: specialistId?.tentUserUuid })
						!_.isEmpty(apptState?.appointmentModes) && formik?.setFieldValue('specialistName', foundIndividualUser)
						setSelectTentUserId(foundIndividualUser?.tentUserUuid)
					} else {
						formik?.setFieldValue('specialistName', apptState?.specialistList.at(0))
						setSelectTentUserId(apptState?.specialistList.at(0)?.tentUserUuid)
					}
				}
			}

			// Time slot
			if (!_.isEmpty(apptState?.timeSlotList) && _.isEmpty(formik?.values?.timeSlot)) {
				formik?.setFieldValue('timeSlot', apptState?.timeSlotList.at(0))
				formik?.setFieldValue('paymentMandatory', apptState?.timeSlotList.at(0).isPaymentMandatory)
			}

			// country code
			if (!_.isEmpty(countryCodeList) && _.isEmpty(countryCode)) {
				let code = _.find(countryCodeList, { mastLookupKey: currentSelectedClient?.custCountryCode })
				setCountryCode(code)
				setCountryCodeLength(parseInt(code?.mastLookupValue))
				setCountryCodeMinLength(parseInt(code?.minimumLength))
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apptState?.appointmentModes, apptState?.specialistList, apptState?.timeSlotList, countryCodeList, countryName])

	useEffect(() => {
		//facility
		if (!isCareFlow && !_.isEmpty(apptState?.facilityList)) {
			formik?.setFieldValue('facility', apptState?.facilityList[0])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apptState?.facilityList])

	useEffect(() => {
		if (isCareFlow && !isEditAppt && !_.isEmpty(apptState?.procedureList) && _.isEmpty(formik?.values?.procedureCategory)) {
			formik?.setFieldValue('procedureCategory', apptState?.procedureList?.at(0))
			formik?.setFieldValue('feesCalcMethod', 'specialistConsultationFees')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [apptState?.procedureList])

	const getClientId = () => {
		QuickSaleAPI.getPatientId(mastuuid).then(
			(res) => {
				let Res = decryption(res)
				formik?.setFieldValue('clientId', Res?.data)
			},
			(err) => {}
		)
	}
	const countryTimezone = useSelector((state) => state?.dynamicMenu?.userData?.countryTimezone)
	const [googleIntegrateLink, setGoogleIntegrateLink] = useState('')
	const googleMeetlinkGeneration = useCallback(() => {
		if (_.isEmpty(defaultIntegrationForOnlineMeeting) || _.isEqual(defaultIntegrationForOnlineMeeting, 'Jitsi')) {
			return dispatch(
				showMessage({
					message: getResponseMessage({}, errorMessages, 'Please integrate a meeting platform'),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		dispatch(showLoader(`Meeting link is generating please wait...`))

		const body = {
			apponmintId: 'string',
			startDate: nextDate ? moment(nextDate).format('YYYY-MM-DD') : null,
			endDate: nextDate ? moment(nextDate).format('YYYY-MM-DD') : null,
			startTime: isCareFlow
				? formik?.values?.timeSlot?.startTime
					? moment(formik?.values?.timeSlot?.startTime, 'hh:mm A').format(`HH:mm`)
					: null
				: formik?.values?.timeSlot?.time
				? moment(formik?.values?.timeSlot?.time, 'hh:mm A').format(`HH:mm`)
				: null,
			endTime: isCareFlow
				? formik?.values?.timeSlot?.startTime && formik?.values?.timeSlot?.intervalInMins
					? moment(formik?.values?.timeSlot?.startTime, 'hh:mm A').add(formik?.values?.timeSlot?.intervalInMins, 'minutes').format(`HH:mm`)
					: null
				: formik?.values?.timeSlot?.time && formik?.values?.timeSlot?.consultantionDuration
				? moment(formik?.values?.timeSlot?.time, 'hh:mm A').add(formik?.values?.timeSlot?.consultantionDuration, 'minutes').format(`HH:mm`)
				: null,
			timezone: countryTimezone?.split(' ')?.[2],
			summary: formik?.values?.appointmentMode?.LabelTxt,
		}

		const zoomBody = {
			mast_tent_uuid: mastuuid,
			start_time: `${nextDate ? moment(nextDate).format('YYYY-MM-DD') : null}T${
				isCareFlow
					? formik?.values?.timeSlot?.startTime
						? moment(formik?.values?.timeSlot?.startTime, 'hh:mm A').format(`HH:mm`)
						: null
					: formik?.values?.timeSlot?.time
					? moment(formik?.values?.timeSlot?.time, 'hh:mm A').format(`HH:mm`)
					: null
			}:00`,
			meeting_topic: 'Appointment',
			time_zone: countryTimezone?.split(' ')?.[2],
			attendees: [{ name: formik?.values?.clientName?.custName }],
			agenda: '',
			duration: formik?.values?.timeSlot?.intervalInMins || formik?.values?.timeSlot?.consultantionDuration || 0,
		}
		let onSuccess = (res) => {
			dispatch(hideLoader())
			if (res?.data?.status === true) {
				_.isEqual(defaultIntegrationForOnlineMeeting, 'Zoom server')
					? setGoogleIntegrateLink(res?.data?.data?.tent_join_url || '')
					: setGoogleIntegrateLink(res?.data?.data?.meeting_link || '')
				dispatch(
					showMessage({
						message: getResponseMessage(res.data, errorMessages),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
			}
		}

		let onFailure = (err) => {
			dispatch(hideLoader())
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorMessages),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'error',
				})
			)
		}
		_.isEqual(defaultIntegrationForOnlineMeeting, 'Zoom server')
			? AppointmentAPI.zoomMeetingLinkGeneration(zoomBody, mastuuid).then(onSuccess, onFailure)
			: _.isEqual(defaultIntegrationForOnlineMeeting, 'google_meet') &&
			  AppointmentAPI.googleMeetingLinkGeneration(body, mastuuid).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryTimezone, dispatch, errorMessages, formik?.values, isCareFlow, mastuuid])

	// Pre-Population of data from Other Modules:
	useEffect(() => {
		if (openedFrom === 'schedulerSpecialist' && !_.isEmpty(prepopulationData)) {
			formik?.setFieldValue('date', moment(prepopulationData?.date).format('YYYY-MM-DD'))
		}
		if (openedFrom === 'schedulerFacility' && !_.isEmpty(prepopulationData)) {
			formik?.setFieldValue('date', moment(prepopulationData?.date).format('YYYY-MM-DD'))
		}
		if (openedFrom === 'schedulerMonth' && !_.isEmpty(prepopulationData)) {
			formik?.setFieldValue('date', moment(prepopulationData?.date).format('YYYY-MM-DD'))
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openedFrom, prepopulationData, formik?.values?.date])

	// ####################  EDIT APPOINTMENT :: STARTS #############################################
	useEffect(() => {
		if (isEditAppt) {
			// Client Details
			let currentClient = {
				custUuid: editAppointmentData?.custUuid,
				custName: editAppointmentData?.custName,
				custMobileNo: editAppointmentData?.custMobileNo,
				custCountryCode: editAppointmentData?.custCountryCode,
				custCustomId: editAppointmentData?.custCustomId,
				custEmail: editAppointmentData?.custEmail || '',
			}

			clientDispatch({
				type: 'setClientSearchText',
				data: currentClient?.custName,
			})
			clientDispatch({
				type: 'setclientStatus',
				data: currentClient?.leadStatus,
			})
			clientDispatch({
				type: 'setIsNewClient',
				data: false,
			})
			handleCloseDropdown()

			formik?.setFieldValue('clientName', currentClient)
			formik?.setFieldValue('custMobile', currentClient?.custMobileNo)
			formik?.setFieldValue('clientId', currentClient?.custCustomId)
			formik?.setFieldValue('clientEmail', currentClient?.custEmail)

			// Country Code
			let code = _.find(countryCodeList, { mastLookupKey: editAppointmentData?.custCountryCode })
			setCountryCode(code)
			setCountryCodeLength(parseInt(code?.mastLookupValue))
			setCountryCodeMinLength(parseInt(code?.minimumLength))

			// setSearchText(currentClient?.custName)

			// Date
			formik?.setFieldValue('date', new Date(editAppointmentData?.start))

			// mode of appointment
			if (!_.isEmpty(appointmentModes)) {
				// let foundMode = apptState?.appointmentModes.find((o) => o?.label === editAppointmentData?.appointmentMode)
				let foundMode = appointmentModes.find((o) => o?.label === editAppointmentData?.appointmentMode)
				formik?.setFieldValue('appointmentMode', foundMode || null)
			}

			// specialist
			if (!_.isEmpty(apptState?.specialistList)) {
				let foundSpecialist = apptState?.specialistList.find((o) => o?.tentUserUuid === editAppointmentData?.tentUserUuid)
				formik?.setFieldValue('specialistName', foundSpecialist)
			}

			// speciality
			if (!_.isEmpty(apptState?.specialityList)) {
				let foundSpeciality = apptState?.specialityList.find((o) => o?.specialityUuid === editAppointmentData?.specialityUuid)
				formik?.setFieldValue('speciality', foundSpeciality || null)
			}

			// facility for non-care
			if (!_.isEmpty(apptState?.facilityList) && !isCareFlow) {
				let foundfacility = apptState?.facilityList.find((o) => o?.specialityUuid === editAppointmentData?.appointmentSpecialityUuid)
				formik?.setFieldValue('facility', foundfacility || null)
			}

			// Procedure category
			if (!_.isEmpty(apptState?.procedureList) && !_.isEmpty(editAppointmentData?.procedure)) {
				let foundProcedure = apptState?.procedureList.find(
					(o) => o?.tentProcedureCatalogName === editAppointmentData?.procedure?.at(0)?.procedureName
				)
				formik?.setFieldValue('procedureCategory', foundProcedure || null)
			}

			// Time slot
			isCareFlow
				? formik?.setFieldValue('timeSlot', {
						startTime: editAppointmentData?.startTime,
				  })
				: formik?.setFieldValue('timeSlot', {
						formattedTime: moment(`${editAppointmentData?.start}T${editAppointmentData?.startTime}`).format('LT'),
						consultantionDuration: Number(editAppointmentData?.scheduledInterval?.substring(0, 3)),
						consultantionFees: editAppointmentData?.totalAppointmentPrice,
				  })

			// Notes
			formik?.setFieldValue('notes', editAppointmentData?.notes)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditAppt, apptState?.appointmentModes, apptState?.specialistList, apptState?.specialityList, apptState?.procedureList])

	// ####################  EDIT APPOINTMENT :: ENDS #############################################

	const handleClear = () => {
		clientDispatch({
			type: 'reset',
		})
		formik?.resetForm()
		setSearchText('')
		dispatch(setAppointmentDate(null))
	}

	function handleCloseDropdown() {
		clientDispatch({
			type: 'setIsShowDropdown',
			data: false,
		})
		clientDispatch({
			type: 'setClientDataList',
			data: [],
		})
	}
	// Default client prepopulation:
	useEffect(() => {
		if (!_.isEmpty(currentSelectedClient) && !isEditAppt && openAddAppointmentModal) {
			// clientDispatch({
			// 	type: 'setIsNewClient',
			// 	data: false,
			// })
			// formik?.setFieldValue('clientName', currentSelectedClient)
			// setSearchText(currentSelectedClient?.custName)
			// formik?.setFieldValue('clientId', currentSelectedClient?.custCustomId)
			// formik?.setFieldValue('custMobile', currentSelectedClient?.custMobileNo)
			// formik?.setFieldValue('clientEmail', currentSelectedClient?.custEmail)
			// // setting the  country code and countryCodeLength
			// let code = _.find(countryCodeList, { mastLookupKey: currentSelectedClient?.custCountryCode })
			// setCountryCode(code)
			// setCountryCodeLength(parseInt(code?.mastLookupValue))
			// setCountryCodeMinLength(parseInt(code?.minimumLength))

			clientDispatch({
				type: 'setClientSearchText',
				data: currentSelectedClient?.custName,
			})
			clientDispatch({
				type: 'setclientStatus',
				data: currentSelectedClient?.leadStatus,
			})
			clientDispatch({
				type: 'setIsNewClient',
				data: false,
			})
			formik?.setFieldValue('clientName', currentSelectedClient)
			formik?.setFieldValue('clientId', currentSelectedClient?.custCustomId)
			formik?.setFieldValue('custMobile', currentSelectedClient?.custMobileNo)
			formik?.setFieldValue('clientEmail', currentSelectedClient?.custEmail)
			// setting the  country code and countryCodeLength
			let code = _.find(countryCodeList, { mastLookupKey: currentSelectedClient?.custCountryCode })
			setCountryCode(code)
			setCountryCodeLength(parseInt(code?.mastLookupValue))
			setCountryCodeMinLength(parseInt(code?.minimumLength))
			handleCloseDropdown()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSelectedClient, openAddAppointmentModal, formik?.values?.date])
	useEffect(() => {
		if (clientState?.isNewClient) {
			let code = _.find(countryCodeList, { mastLookupKey: currentCountCode })
			setCountryCode(code)
			setCountryCodeLength(parseInt(code?.mastLookupValue))
			setCountryCodeMinLength(parseInt(code?.minimumLength))
		} else {
			setCountryCode(null)
			setCountryCodeLength(0)
			setCountryCodeMinLength(1)
		}
		// setting the  country code and countryCodeLength
	}, [clientState?.isNewClient, countryCodeList, currentCountCode])

	return (
		<CustomDialog
			open={openAddAppointmentModal}
			headerText={isEditAppt ? 'Reschedule Appointment' : 'Add Appointment'}
			onClose={onClose}
			maxWidth='md'
			hideCloseBtn
			hideSaveBtn
			hideDivider
			dialogActions={false}
			customStyles={customStyles}
			formikValues={formik}
		>
			<div>
				{!startPaymentProcess && (
					<>
						<div className={classes.container}>
							{isCareFlow ? (
								<CareAppointment
									formik={formik}
									apptState={apptState}
									nextDate={nextDate}
									apptDispatch={apptDispatch}
									clientState={clientState}
									clientDispatch={clientDispatch}
									isSpecialistLogin={isSpecialistLogin}
									countryCodeList={countryCodeList}
									countryCode={countryCode}
									setCountryCode={setCountryCode}
									setCountryCodeLength={setCountryCodeLength}
									setCountryCodeMinLength={setCountryCodeMinLength}
									searchText={searchText}
									setGoogleIntegrateLink={setGoogleIntegrateLink}
									setSearchText={setSearchText}
									clientSearcher={clientSearcher}
									getClientId={getClientId}
									isEditAppt={isEditAppt}
									preSelectedClient={preSelectedClient}
									// ###
									isCareFlow={isCareFlow}
									performClientSearch={performClientSearch}
									googleMeetlinkGeneration={googleMeetlinkGeneration}
									googleIntegrateLink={googleIntegrateLink}
									setSelectTentUserId={setSelectTentUserId}
									timeSlotLoading={timeSlotLoading}
								/>
							) : (
								<NonCareAppointment
									formik={formik}
									apptState={apptState}
									apptDispatch={apptDispatch}
									firstFilterDate={firstFilterDate}
									nextDate={nextDate}
									setFirstFilterDate={setFirstFilterDate}
									clientState={clientState}
									clientDispatch={clientDispatch}
									countryCodeList={countryCodeList}
									countryCode={countryCode}
									setCountryCode={setCountryCode}
									setCountryCodeLength={setCountryCodeLength}
									setCountryCodeMinLength={setCountryCodeMinLength}
									setGoogleIntegrateLink={setGoogleIntegrateLink}
									searchText={searchText}
									setSearchText={setSearchText}
									clientSearcher={clientSearcher}
									getClientId={getClientId}
									isEditAppt={isEditAppt}
									preSelectedClient={preSelectedClient}
									// ###
									isCareFlow={isCareFlow}
									performClientSearch={performClientSearch}
									googleMeetlinkGeneration={googleMeetlinkGeneration}
									googleIntegrateLink={googleIntegrateLink}
									setSelectTentUserId={setSelectTentUserId}
									timeSlotLoading={timeSlotLoadingNonCare}
									price={price}
									setPrice={setPrice}
									setPriceValue={setPriceValue}
								/>
							)}
						</div>
						<div className={classes.footerAction}>
							<div>
								<Typography variant='subtitle1' fontWeight={600}>
									The system will automatically retrieve the next available date and time if the chosen date slot is unavailable by default.
								</Typography>
							</div>
							<div style={{ display: 'flex', gap: 12 }}>
								<CancelBtn disabled={isEditAppt} onClick={handleClear}>
									Clear
								</CancelBtn>
								<CustomBtn
									onClick={() => {
										formik?.handleSubmit()
									}}
								>
									{isEditAppt ? 'Reschedule' : 'Add Appointment'}
								</CustomBtn>
							</div>
						</div>
					</>
				)}

				{startPaymentProcess && (
					<>
						<PaymentHandler isCareFlow={isCareFlow} billingData={billingData} onClose={onClose} />
					</>
				)}
			</div>
		</CustomDialog>
	)
}

export default AddAppointment
