import React, { useCallback, useEffect, useState } from 'react'
import { Box, Grid, TextField, Typography, InputAdornment } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomBtn from 'app/sharedComponents/Button'
import { useDispatch, useSelector } from 'react-redux'
import { showMessage } from 'app/store/fuse/messageSlice'
import _ from 'lodash'
import { hideLoader, showLoader } from 'services/loader/action'
import customLabelsApi from '../../../../services/ProfileSetup/Customlabels/api'
import { getResponseMessage } from 'utils'

const useStyles = makeStyles((theme) => ({
	domainField: {
		'& .MuiOutlinedInput-root': {
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root.Mui-focused': {
			border: '1px solid #C4C4C4',
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root.Mui-error': {
			borderRadius: 8,
		},
		'& .MuiOutlinedInput-root.Mui-focused.Mui-error': {
			border: 'none',
			borderRadius: 8,
		},
	},
	domainLabel: {
		fontWeight: 600,
		fontSize: 13,
		marginBottom: 6,
	},
	seoDescriptionLength: {
		position: 'absolute',
		bottom: '8px',
		right: '8px',
		fontWeight: 500,
		color: '#000',
	},
	buttonMain: {
		display: 'flex',
		gap: 16,
		position: 'fixed',
		width: '100%',
		bottom: 0,
		left: 0,
		padding: '30px',
		zIndex: '999',
		background: '#fff',
		justifyContent: 'flex-end',
	},
	errorText: {
		'& .MuiTypography-root': {
			color: 'red',
		},
	},
}))

const CustomLabels = ({ moveBackTab, moveNextTab }) => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const auth = useSelector((state) => state?.Authentication)
	const { mastuuid } = auth
	const groupUserName = useSelector((state) => state?.GroupUser?.groupUserName)
	const errorJson = useSelector((state) => state?.errorMessages || {})
	const groupName = useSelector((state) => state?.dynamicMenu?.userData?.groupName)
	const isCare = _.isEqual(groupName, 'health care')
	const [domainName, setDomainName] = useState('')

	const initialValues = {
		aboutPractise: '',
		specialist: '',
		events: '',
		membership: '',
		facility: '',
	}

	const validationSchema = Yup.object({
		// aboutPractise: Yup.string().max(60, 'About Practice must be 60 characters or less').required('Meta title is required'),
		// specialist: Yup.string().max(60, 'Specialist must be 60 characters or less').required('Meta title is required'),
		// events: Yup.string().max(60, 'Events must be 60 characters or less').required('Meta title is required'),
		// membership: Yup.string().max(60, 'Membership must be 60 characters or less').required('Meta title is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: (values) => {
			postCustomLabels(values)
		},
	})

	const postCustomLabels = (values) => {
		const body = {
			aboutPractise: values.aboutPractise,
			specialist: values.specialist,
			events: values.events,
			membership: values?.membership,
			facility: values?.facility,
		}
		const onSuccess = (res) => {
			if (res?.data?.status === 'success') {
				dispatch(
					showMessage({
						message: getResponseMessage(res, errorJson, res?.data?.message),
						autoHideDuration: 3000,
						anchorOrigin: {
							vertical: 'top',
							horizontal: 'right',
						},
						variant: 'success',
					})
				)
				moveNextTab()
			}
		}
		const onFailure = (err) => {
			dispatch(
				showMessage({
					message: getResponseMessage(err?.response?.data, errorJson),
					autoHideDuration: 3000,
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right',
					},
					variant: 'success',
				})
			)
		}
		customLabelsApi.postCustomLabels({ customDetails: { ...body } }, mastuuid, domainName).then(onSuccess, onFailure)
	}

	const getSeoDetails = useCallback(() => {
		dispatch(showLoader('Loading please wait...'))

		const onSuccess = (res) => {
			dispatch(hideLoader())
			setDomainName(res?.data?.data?.domainName)
			formik.setValues({
				aboutPractise: res?.data?.data?.customLabels?.aboutPractise || '',
				specialist: res?.data?.data?.customLabels?.specialist || '',
				events: res?.data?.data?.customLabels?.events || '',
				membership: res?.data?.data?.customLabels?.membership || '',
				facility: res?.data?.data?.customLabels?.facility || '',
			})
		}

		const onFailure = (err) => {
			dispatch(hideLoader())
		}

		mastuuid && customLabelsApi.getCustomLabels(mastuuid).then(onSuccess, onFailure)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, mastuuid])

	useEffect(() => {
		getSeoDetails()
	}, [getSeoDetails])

	return (
		<Box minHeight={'55vh'}>
			<form onSubmit={formik.handleSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} className={classes.domainField}>
						<Typography className={classes.domainLabel}>About Practice</Typography>
						<TextField
							size='small'
							fullWidth
							id='aboutPractise'
							placeholder='About Practice'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Typography fontWeight={500} color={'#000'}>
											{formik?.values?.aboutPractise?.length || 0}/40
										</Typography>
									</InputAdornment>
								),
							}}
							inputProps={{ maxLength: 40 }}
							value={formik?.values?.aboutPractise}
							onChange={(e) => formik.setFieldValue('aboutPractise', e.target.value)}
							// onBlur={formik.handleBlur}
							// error={formik.touched.aboutPractise && Boolean(formik.errors.aboutPractise)}
							// helperText={formik.touched.aboutPractise && formik.errors.aboutPractise}
						/>
					</Grid>
					<Grid item xs={12} sm={6} className={classes.domainField}>
						<Typography className={classes.domainLabel}>Specialists</Typography>
						<TextField
							size='small'
							fullWidth
							id='specialist'
							placeholder='Specialists'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Typography fontWeight={500} color={'#000'}>
											{formik?.values?.specialist?.length || 0}/40
										</Typography>
									</InputAdornment>
								),
							}}
							inputProps={{ maxLength: 40 }}
							value={formik?.values?.specialist}
							onChange={(e) => formik.setFieldValue('specialist', e.target.value)}
							// onBlur={formik.handleBlur}
							// error={formik.touched.specialist && Boolean(formik.errors.specialist)}
							// helperText={formik.touched.specialist && formik.errors.specialist}
						/>
					</Grid>
					<Grid item xs={12} sm={6} className={classes.domainField}>
						<Typography className={classes.domainLabel}>Events</Typography>
						<TextField
							size='small'
							fullWidth
							id='events'
							placeholder='Events'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Typography fontWeight={500} color={'#000'}>
											{formik?.values?.events?.length || 0}/40
										</Typography>
									</InputAdornment>
								),
							}}
							inputProps={{ maxLength: 40 }}
							value={formik?.values?.events}
							onChange={(e) => formik.setFieldValue('events', e.target.value)}
							// onBlur={formik.handleBlur}
							// error={formik.touched.events && Boolean(formik.errors.events)}
							// helperText={formik.touched.events && formik.errors.events}
						/>
					</Grid>
					<Grid item xs={12} sm={6} className={classes.domainField}>
						<Typography className={classes.domainLabel}>Membership</Typography>
						<TextField
							size='small'
							fullWidth
							id='membership'
							placeholder='Membership'
							InputProps={{
								endAdornment: (
									<InputAdornment position='end'>
										<Typography fontWeight={500} color={'#000'}>
											{formik?.values?.membership?.length || 0}/40
										</Typography>
									</InputAdornment>
								),
							}}
							inputProps={{ maxLength: 40 }}
							value={formik?.values?.membership}
							onChange={(e) => formik.setFieldValue('membership', e.target.value)}
							// onBlur={formik.handleBlur}
							// error={formik.touched.membership && Boolean(formik.errors.membership)}
							// helperText={formik.touched.membership && formik.errors.membership}
						/>
					</Grid>
					{!isCare && (
						<Grid item xs={12} sm={6} className={classes.domainField}>
							<Typography className={classes.domainLabel}>Facility</Typography>
							<TextField
								size='small'
								fullWidth
								id='facility'
								placeholder='Facility'
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<Typography fontWeight={500} color={'#000'}>
												{formik?.values?.facility?.length || 0}/40
											</Typography>
										</InputAdornment>
									),
								}}
								inputProps={{ maxLength: 40 }}
								value={formik?.values?.facility}
								onChange={(e) => formik.setFieldValue('facility', e.target.value)}
								// onBlur={formik.handleBlur}
								// error={formik.touched.facility && Boolean(formik.errors.facility)}
								// helperText={formik.touched.facility && formik.errors.facility}
							/>
						</Grid>
					)}
				</Grid>
				<div className={classes.buttonMain}>
					<CustomBtn onClick={() => moveBackTab()}>Back</CustomBtn>
					<CustomBtn type='submit'>{_.isEqual(groupUserName, 'owner') ? 'Save' : 'Save & Next'}</CustomBtn>
				</div>
			</form>
		</Box>
	)
}

export default CustomLabels
